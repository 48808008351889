<template>
  <div class="page">
    <img
      v-for="(item, index) in optList"
      :key="index"
      :src="item.iconUrl"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "decorationTipsOtherDetail",
  data() {
    return {
      optList: [
        { iconUrl: require("./img/1.jpg") },
        { iconUrl: require("./img/2.jpg") },
        { iconUrl: require("./img/3.jpg") },
        { iconUrl: require("./img/4.jpg") },
        { iconUrl: require("./img/5.jpg") },
        { iconUrl: require("./img/6.jpg") },
      ],
    };
  },
  async created() {},
  methods: {},
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  img {
    width: 100%;
    display: block;
  }
}
</style>
